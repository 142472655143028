import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { firebaseConfig } from "@/firebaseConfig/config";

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});
firebaseApp.firestore().enablePersistence({ synchronizeTabs: true });

// Export firebase
export const db = firebaseApp.firestore();
export const functions = firebaseApp.functions("europe-west3");
export const fbProjectId = firebaseConfig.projectId;

// Export Collections
export const courseCollection = db.collection("Course");
export const companyCollection = db.collection("Company");
export const groupCollection = db.collection("Group");
export const rhoUserCollection = db.collection("RhoUser");
export const progressCollection = db.collection("Progress");
export const profileCollection = db.collection("Profile");
export const adminCollection = db.collection("Admin");
export const batchCollection = db.collection("Batch");
export const coachCollection = db.collection("Coach");
export const userInformationCollection = db.collection("UserInformation");
export const userProgressCalculationCollection = db.collection(
  "UserProgressCalculation"
);

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };

export async function userIsAdmin(email: string | null | undefined) {
  if (!email) {
    return false;
  }
  const res = adminCollection
    .doc(email)
    .get()
    .then((ref) => {
      if (ref.exists) {
        return (
          ref.data()?.permissions.includes("read") &&
          ref.data()?.permissions.includes("write")
        );
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
  return res;
}

export async function userIsCoach(userId: string | null | undefined) {
  const res = coachCollection
    .where("userId", "==", userId)
    .get()
    .then((snapshot) => {
      return !snapshot.empty;
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
  return res;
}
