import {
  groupCollection,
  rhoUserCollection,
  progressCollection,
  userProgressCalculationCollection,
  companyCollection,
  courseCollection,
  profileCollection,
  batchCollection,
  coachCollection,
  userInformationCollection,
} from "@/utils/db";
import firebase from "firebase/app";
import "firebase/firestore";
import { firestoreAction } from "vuexfire";

export default {
  // Vuexfire bindings
  bindGroupsRef: firestoreAction((context) => {
    return context.bindFirestoreRef("groups", groupCollection);
  }),
  bindRhoUserRef: firestoreAction((context) => {
    return context.bindFirestoreRef("rhoUsers", rhoUserCollection);
  }),
  bindProgressRef: firestoreAction((context) => {
    return context.bindFirestoreRef("progress", progressCollection);
  }),
  bindUserProgressCalculationRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "userProgressCalculation",
      userProgressCalculationCollection
    );
  }),
  bindCourseRef: firestoreAction((context) => {
    return context.bindFirestoreRef("allCourses", courseCollection);
  }),
  bindCompaniesRef: firestoreAction((context) => {
    return context.bindFirestoreRef("companies", companyCollection);
  }),
  bindProfilesRef: firestoreAction((context) => {
    return context.bindFirestoreRef("profiles", profileCollection);
  }),
  bindBatchRef: firestoreAction((context) => {
    return context.bindFirestoreRef("batches", batchCollection);
  }),
  bindCoachesRef: firestoreAction((context) => {
    return context.bindFirestoreRef("coaches", coachCollection);
  }),
  bindUserInformationRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "userInformation",
      userInformationCollection
    );
  }),
  // Write to firebase
  // See: https://vuefire.vuejs.org/vuexfire/writing-data.html#updates-to-collection-and-documents
  /** Add document to firebase*/
  addDocument: firestoreAction(
    (
      context,
      payload: {
        collection: firebase.firestore.CollectionReference;
        data: firebase.firestore.DocumentData;
      }
    ) => {
      return payload.collection.add(payload.data);
    }
  ),
  /** Add document to firebase with custom document id*/
  addDocumentWithCustomId: firestoreAction(
    (
      context,
      payload: {
        collection: firebase.firestore.CollectionReference;
        data: firebase.firestore.DocumentData;
        documentId: string;
      }
    ) => {
      const docRef = payload.collection.doc(payload.documentId);
      return docRef.set(payload.data);
    }
  ),
  /** Update single or multiple fields in a document */
  updateDocument: firestoreAction(
    (
      context,
      payload: {
        document: firebase.firestore.DocumentReference;
        data: firebase.firestore.DocumentData;
      }
    ) => {
      return payload.document.update(payload.data);
    }
  ),
  /** Override data in a document*/
  setDocument: firestoreAction(
    (
      context,
      payload: {
        document: firebase.firestore.DocumentReference;
        data: firebase.firestore.DocumentData;
      }
    ) => {
      return payload.document.set(payload.data);
    }
  ),
  /** Delete a document in firebase*/
  deleteDocument: firestoreAction(
    (context, payload: { document: firebase.firestore.DocumentReference }) => {
      return payload.document.delete();
    }
  ),
  // Other
  fetchUser({ commit, dispatch }, user: firebase.User): void {
    commit("SET_LOGGED_IN", user !== null);
    if (user) {
      commit("SET_USER", {
        displayName: user.displayName,
        email: user.email,
      });
    } else {
      commit("SET_USER", null);
    }
  },
};
