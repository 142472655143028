import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { firestorePlugin } from "vuefire";
import vuetify from "./plugins/vuetify";
import dotenv from "dotenv";
import { userIsAdmin, userIsCoach } from "./utils/db";
import firebase from "firebase/app";
import "firebase/auth";
import VueMoment from "vue-moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
dotenv.config();
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { validateCoachUid } from "./utils/utils";

// VUE
Vue.config.productionTip = false;
Vue.use(VueMoment);
Vue.use(VueVirtualScroller);
Vue.use(firestorePlugin); //{ serialize }
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

let vApp;

// FIREBASE + Vue init
firebase.auth().onAuthStateChanged(asyncHandler);

async function asyncHandler(user) {
  if (!vApp) {
    vApp = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }

  store.commit("RESET_ADMIN");
  store.commit("RESET_COACH");

  if (user) {
    validateCoachUid(user);
    if (await userIsAdmin(user.email)) {
      store.dispatch("bindGroupsRef");
      store.dispatch("bindRhoUserRef");
      store.dispatch("bindProgressRef");
      store.dispatch("bindUserProgressCalculationRef");
      store.dispatch("bindCourseRef");
      store.dispatch("bindCompaniesRef");
      store.dispatch("bindProfilesRef");
      store.dispatch("bindBatchRef");
      store.dispatch("bindCoachesRef");
      store.dispatch("bindUserInformationRef");
      store.commit("SET_ADMIN", { permissions: ["read", "write"] });
    } else if (await userIsCoach(user.uid)) {
      store.dispatch("bindCourseRef");
      store.dispatch("bindCoachesRef");
      store.dispatch("bindUserInformationRef");
      store.commit("SET_COACH", { permissions: ["read"] });
    } else {
      router.replace("/noPerm");
    }
  }
  store.dispatch("fetchUser", user).then(() => {
    if (!user) router.replace("/login");
  });
}

export const MODE = process.env.NODE_ENV;
