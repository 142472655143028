

























































































import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import AddTestUser from "@/components/AddTestUser.vue";
import { mapGetters } from "vuex";
import { fbProjectId } from "@/utils/db";
import { MODE } from "./main";

export default Vue.extend({
  name: "App",
  components: { AddTestUser },
  data: () => ({
    fbProjectId: fbProjectId,
    projectMode: MODE,
    addTestUserDialog: false as boolean,
  }),
  computed: {
    ...mapGetters([
      "notes",
      "groups",
      "user",
      "admin",
      "userIsCoach",
      "userIsAdmin",
    ]),
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        });
    },
    navigateTo(path) {
      this.$router.push(path);
    },
  },
});
