import { Course } from "@/models/Course";
import {
  Batch,
  Coach,
  CoachingGroup,
  Company,
  Progress,
  RhoUser,
  UserProgress,
} from "@/models/models";

export default {
  user(state): any[] {
    return state.user;
  },
  groups(state): CoachingGroup[] {
    return state.groups.sort();
  },
  sortedGroups(state): CoachingGroup[] {
    const items = [...state.groups].sort((a, b) => {
      if (a.coachings.length > 0 && b.coachings.length > 0) {
        if (String(a.coachings[0].start) > String(b.coachings[0].start)) {
          return -1;
        }
        if (String(a.coachings[0].start) < String(b.coachings[0].start)) {
          return 1;
        }
      }
      return 0;
    });

    return items;
  },
  rhoUsers(state): RhoUser[] {
    return state.rhoUsers;
  },
  progress(state): Progress[] {
    return state.progress;
  },
  userProgressCalculation(state): UserProgress[] {
    return state.userProgressCalculation;
  },
  allCourses(state): Course[] {
    return state.allCourses;
  },
  companies(state): Company[] {
    return state.companies;
  },
  sortedCompanies(state): Company[] {
    const items = [...state.companies].sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else {
        return -1;
      }
    });
    return items;
  },
  profiles(state) {
    return state.profiles;
  },
  admin(state) {
    return state.admin;
  },
  batches(state) {
    return state.batches;
  },
  sortedBatches(state): Batch[] {
    const items = [...state.batches].sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      } else {
        return -1;
      }
    });
    return items;
  },
  coaches(state) {
    return state.coaches;
  },
  userInformation(state) {
    return state.userInformation;
  },
  userIsCoach(state) {
    if (state.coach.permissions)
      return state.coach.permissions.includes("read");
    else return false;
  },
  userIsAdmin(state) {
    if (state.admin.permissions) {
      return Boolean(
        state.admin.permissions.includes("read") &&
          state.admin.permissions.includes("write")
      );
    } else return false;
  },
};
