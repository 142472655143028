import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#11D4C3",
        secondary: "#EE2E94",
        accent: "#FBAB00",
        error: "#E95062",
      },
    },
  },
});
