











































import Vue from "vue";
import { settings } from "@/utils/settings";
import { CoachingGroup, Company } from "@/models/models";
import { mapGetters } from "vuex";
import { companyCollection, groupCollection } from "@/utils/db";
export default Vue.extend({
  props: {
    value: Boolean,
  },
  data: () => ({
    formValid: false,
    settings: settings,
    testUserEmail: null as string | null,
    testUserCourses: [] as string[],
  }),
  computed: {
    addTestUserDialog: {
      get(): boolean {
        return this.value;
      },
      set(value): void {
        this.$emit("input", value);
      },
    } as any,
    ...mapGetters(["companies", "groups", "allCourses"]),
    testCompany(): Company | undefined {
      return this.companies.find(
        (item: Company) => item.id == this.settings.testCompanyId
      );
    },
    testCompanyCourses(): string[] {
      return this.testCompany?.courses.map((e) => e.courseId) || [];
    },
    testGroup(): CoachingGroup {
      return this.groups.find(
        (item: Company) => item.id == this.settings.testGroupId
      );
    },
  },
  watch: {
    addTestUserDialog: {
      handler(val) {
        this.testUserCourses = this.settings.testCourses;
      },
    },
  },
  methods: {
    saveCurrentTestUser(): void {
      if (this.testUserEmail) {
        let testUser = {
          email: this.testUserEmail,
          courses: this.testUserCourses,
        };

        // 1. add user to test company
        if (this.testCompany) {
          this.testCompany.courses.forEach((course) => {
            if (testUser.courses.includes(course.courseId)) {
              course.users.indexOf(testUser.email) === -1
                ? course.users.push(testUser.email)
                : {};
            } else {
              var index = course.users.indexOf(testUser.email);
              if (index !== -1) {
                course.users.splice(index, 1);
              }
            }
          });
          this.testCompany.allUsers.indexOf(testUser.email) === -1
            ? this.testCompany.allUsers.push(testUser.email)
            : {};
          this.$store.dispatch("updateDocument", {
            data: this.testCompany,
            document: companyCollection.doc(this.testCompany.id),
          });
        } else {
          alert("No Test Company!");
        }

        // 2. add user to test group
        if (this.testGroup) {
          this.testGroup.users.indexOf(testUser.email) === -1
            ? this.testGroup.users.push(testUser.email)
            : {};
          this.$store.dispatch("updateDocument", {
            document: groupCollection.doc(this.testGroup.id),
            data: { ...this.testGroup },
          });
        } else {
          alert("no Test Group!");
        }
        this.testUserEmail = null;
        this.testUserCourses = settings.testCourses;
        this.addTestUserDialog = false;
      } else {
        alert("Empty user");
      }
    },
  },
});
