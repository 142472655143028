import Vue from "vue";
import { firestoreOptions } from "vuexfire";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import { firestorePlugin } from "vuefire";
import Vuex from "vuex";
import { Course } from "@/models/Course";
import {
  Batch,
  Coach,
  CoachingGroup,
  Company,
  Progress,
  RhoUser,
  UserInformation,
  UserProgress,
} from "@/models/models";

Vue.use(Vuex);
Vue.use(firestorePlugin);

// always wait for bindings to be resolved
firestoreOptions.wait = true;

export default new Vuex.Store({
  state: {
    groups: [] as CoachingGroup[],
    profiles: [],
    batches: [] as Batch[],
    coaches: [] as Coach[],
    userInformation: [] as UserInformation[],
    coach: {},
    admin: {},
    rhoUsers: [] as RhoUser[],
    progress: [] as Progress[],
    userProgressCalculation: [] as UserProgress[],
    allCourses: [] as Course[],
    companies: [] as Company[],
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: getters,
  mutations: mutations,

  actions: actions,
  modules: {},
});
