import { CoachingGroup } from "@/models/models";
import store from "@/store";
import moment from "moment";
import { coachCollection } from "./db";

// https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-array
export function getObjectByProp(theObject, property, id) {
  let result;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getObjectByProp(theObject[i], property, id);
      if (result) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      if (prop == property) {
        if (theObject[prop] == id) {
          return theObject;
        }
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getObjectByProp(theObject[prop], property, id);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}

export function getGroupName(group: CoachingGroup): string {
  let name = group.name || group.id || "";

  if (group.coachings.length > 0) {
    const localeDate = moment.utc(group.coachings[0].start).local();

    if (localeDate.isValid()) {
      const ds = localeDate.format("YYYY.MM.DD HH:mm:SS");
      name = ds + "  -  " + group.coachings[0].coach;
    }
  }
  return name;
}

export function getUserNameByEmail(email: string): string | undefined {
  const userInformation = store.getters.userInformation.find((userInfo) => {
    return userInfo.email === email;
  });
  return userInformation?.name || undefined;
}

export function getUserContactEmailByEmail(email: string): string | undefined {
  const userInformation = store.getters.userInformation.find((userInfo) => {
    return userInfo.email === email;
  });
  return userInformation?.contactEmail || undefined;
}

export function getCourseTitle(courseId: string): string {
  const course =
    store.getters.allCourses.find((c) => c.id.split("_")[0] === courseId) ||
    courseId;

  return course.title;
}

/**
 * Check if coach entry without uid exists for this email.
 * If so -> add current uid.
 */
export async function validateCoachUid(user) {
  const res: any = await coachCollection
    .doc(user.email)
    .get()
    .then((snapshot) => {
      return snapshot.data();
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
  if (res) {
    if (!res.userId) {
      store
        .dispatch("updateDocument", {
          document: coachCollection.doc(user.email),
          data: {
            userId: user.uid,
          },
        })
        .then(() => console.log("Coach uid updated"));
    }
  }
}
