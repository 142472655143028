import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import { userIsAdmin, userIsCoach } from "@/utils/db";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      coachRequired: true,
    },
  },
  {
    path: "/batch",
    name: "Batch",
    component: () =>
      import(/* webpackChunkName: "batch" */ "../views/Batch.vue"),
    meta: {
      adminRequired: true,
    },
    children: [
      {
        path: "/batch/:batchId",
        name: "selectedBatch",
        props: true,
      },
    ],
  },
  {
    path: "/workflows/add-batch",
    name: "AddBatchWorkflow",
    component: () =>
      import(
        /* webpackChunkName: "addBatch" */ "../views/workflows/addBatch.vue"
      ),
    meta: {
      adminRequired: true,
    },
  },
  {
    path: "/company",
    name: "Company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/Company.vue"),
    meta: {
      adminRequired: true,
    },
    children: [
      {
        path: "/company/:companyId",
        name: "selectedCompany",
        props: true,
      },
    ],
  },
  {
    path: "/coaches",
    name: "Coaches",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/Coaches.vue"),
    meta: {
      adminRequired: true,
    },
  },
  {
    path: "/coach-overview",
    name: "Coach-overview",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/CoachOverview.vue"),
    meta: {
      coachRequired: true,
    },
    children: [
      {
        path: "/coach-overview/:coachingGroupId",
        name: "selectedCoachingGroup",
        props: true,
      },
    ],
  },
  {
    path: "/coaching-group",
    name: "CoachingGroup",
    component: () =>
      import(
        /* webpackChunkName: "coachinggroup" */ "../views/CoachingGroup.vue"
      ),
    meta: {
      adminRequired: true,
    },
    children: [
      {
        path: "/coaching-group/:coachingGroupId",
        name: "selectedCoachingGroup",
        props: true,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "selectedcoachinggroup" */ "../views/Login.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      adminRequired: true,
    },
  },
  {
    path: "/noPerm",
    name: "noPerm",
    component: () =>
      import(
        /* webpackChunkName: "selectedcoachinggroup" */ "../views/NoPerm.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.adminRequired)) {
    if (firebase.auth().currentUser) {
      if (await userIsAdmin(firebase.auth().currentUser?.email)) {
        next();
      } else {
        next({ path: "/noPerm" });
      }
    } else {
      next({
        name: "Login",
        query: { redirect: to.path },
      });
    }
  } else if (to.matched.some((record) => record.meta.coachRequired)) {
    if (firebase.auth().currentUser) {
      if (
        (await userIsCoach(firebase.auth().currentUser?.uid)) ||
        (await userIsAdmin(firebase.auth().currentUser?.email))
      ) {
        next();
      } else {
        next({ path: "/noPerm" });
      }
    } else {
      next({
        name: "Login",
        query: { redirect: to.path },
      });
    }
  } else {
    next();
  }
});

export default router;
