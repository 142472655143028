import Vue from "vue";
import { vuexfireMutations } from "vuexfire";

export default {
  RESET_ADMIN(state) {
    Vue.set(state, "admin", {});
  },
  SET_ADMIN(state, payload) {
    Vue.set(state.admin, "permissions", payload.permissions);
  },
  RESET_COACH(state) {
    Vue.set(state, "coach", {});
  },
  SET_COACH(state, payload) {
    Vue.set(state.coach, "permissions", payload.permissions);
  },
  SET_LOGGED_IN(state, value: boolean): void {
    Vue.set(state.user, "loggedIn", value);
  },
  SET_USER(state, data: any): void {
    Vue.set(state.user, "data", data);
  },
  ...vuexfireMutations,
};
